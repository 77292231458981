//reacttails/src/App.js

import './customstyles.css';
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Main from './Main';
import Filter from './filter';
import Introslider from './Introslider';
import LoginPage from './Login';

const App = () => {
  const [navTitle, setNavTitle] = useState('');
  const [isintroSelected, setIsIntroSelected] = useState(true);

  // useEffect(() => {
  //   setNavTitle('Data Lake');
  // }, []);

  const handleNavbarItemClick = (selectedItem) => {
    setNavTitle(selectedItem);
    // setSelectedFilters({
    //   assetTypes: [],
    //   industries: [],
    // });
  };

  function resetFilters() {
    console.log("Filters are reset")
    setSelectedFilters({
      assetTypes: [],
      industries: []
    });
  }

  const [selectedFilters, setSelectedFilters] = useState({
    assetTypes: [],
    industries: [],
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLogin = (password) => {
    const validPassword = 'y1Mga7R2TrCb';

    if (password === validPassword) {
      setIsLoggedIn(true);
    } else {
      alert('Invalid password');
    }
  };

  return (
    <div className="font-poppins h-screen">
      {/* {isLoggedIn ? (
        <div className="flex flex-col h-full">
          <Navbar onItemClick={handleNavbarItemClick} setIsIntroSelected={setIsIntroSelected} resetFilters={resetFilters} className="bg-gray-100" />
          {isintroSelected ? (
            <Introslider/>
          ) : (
            <div className='max-w-screen-3xl ml-auto mr-auto'>
              <div className="flex flex-1 ">
                <div className="flex flex-col h-full bg-gray-100">
                  <Filter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </div>
                <div className="flex-1 overflow-y-auto bg-white">
                  <div>
                    <Header navTitle={navTitle} />
                    <Main selectedFilters={selectedFilters} navTitle={navTitle} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoginPage handleLogin={handleLogin} />
      )} */}
      <div className="flex flex-col h-full">
          <Navbar onItemClick={handleNavbarItemClick} setIsIntroSelected={setIsIntroSelected} resetFilters={resetFilters} className="bg-gray-100" />
          {isintroSelected ? (
            <Introslider/>
          ) : (
            <div className='max-w-screen-3xl ml-auto mr-auto'>
              <div className="flex flex-1 ">
                <div className="flex flex-col h-full bg-gray-100">
                  <Filter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </div>
                <div className="flex-1 overflow-y-auto bg-white">
                  <div>
                    <Header navTitle={navTitle} />
                    <Main selectedFilters={selectedFilters} navTitle={navTitle} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
    </div>
  );
}

export default App;
