import React, { useState } from "react";
import mactoresBlackLogo from './images/mactores-black-logo.png'
function LoginPage({ handleLogin }) {
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogin(password);
    };
    return (
        <div>
            <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center font-poppins">
                <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
                    <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                        <div className="my-12 border-b text-center">
                            <div>
                                <img className="mx-auto h-auto w-60 px-3 py-2 rounded" src={mactoresBlackLogo} alt="Aedeon" />
                            </div>
                            <h1 className="text-2xl xl:text-3xl font-extrabold my-6">
                                Login
                            </h1>
                            <form onSubmit={handleSubmit}>
                                <div className="mx-auto max-w-xs">
                                    {/* <input className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white" type="email" placeholder="Email" fdprocessedid="iyh8yj" /> */}

                                    <input
                                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 mb-5"
                                        type="password"
                                        placeholder="Password"
                                        fdprocessedid="ph02u"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        required />
                                    <button className="mt-5 mb-4 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none" type="submit" fdprocessedid="j9zec">
                                        <span className="ml-3">Login</span>
                                    </button>

                                </div>
                            </form>
                        </div>

                        <p className="mt-6  text-center font-bold text-sm text-blue-500 hover:text-blue-800">
                            <a href="#">Forgot Password?</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default LoginPage;  