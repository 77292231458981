import React from 'react';

const canvaPresentationUrls = [
  "https://www.canva.com/design/DAFuZjB21v4/view?embed",
  "https://www.canva.com/design/DAFuZuY061I/view?embed",
  "https://www.canva.com/design/DAFuZvXBVKE/view?embed",
  "https://www.canva.com/design/DAFuZt7wL5s/view?embed",
  "https://www.canva.com/design/DAFuZulL7P0/view?embed",
  "https://www.canva.com/design/DAFuZqlcH7M/view?embed",
  "https://www.canva.com/design/DAFuZsSjozY/view?embed",
  "https://www.canva.com/design/DAFuZ5F7nC4/view?embed",
  // Add more URLs as needed
];

function Introslider() {
  return (
    <>
      {canvaPresentationUrls.map((url, index) => (
        <div key={index}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '0',
              paddingTop: '56.280%',
              paddingBottom: '0',
              boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
              overflow: 'hidden',
              willChange: 'transform',
            }}
          >
            <iframe
            title={`${index}`}
              loading="lazy"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                border: 'none',
                padding: '0',
                margin: '0',
              }}
              src={url}
              allowFullScreen="allowFullScreen"
              allow="fullscreen"
            ></iframe>
          </div>
        </div>
      ))}
    </>
  );
}

export default Introslider;
