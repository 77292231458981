import React, { useState } from 'react';
import './customstyles.css'
import ListData from './ListData';
// import Popup from './Popup';
function Card({ title, subtitle, ButtonText, Links, assetType, image }) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
    className={`group transition-transform transform-gpu hover:scale-105 ${hovered ? 'hovered' : ''}`}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <div className="group font-poppins min-h-full">
    <a href={Links} target="_blank " rel="noreferrer">
      <div className="group flex cursor-pointer flex-col h-full items-start gap-2s overflow-hidden rounded-md  shadow-md transition-all duration-300 hover:shadow-xl">
        <img src={image} alt='Contains a pic' className="transition-all duration-300 group-hover:opacity-90 xl:h-74 w-full" />
        <span className="custom-body-regular-bold text-white -mt-8 ml-4 transition-all duration-300">{assetType}</span>
        <div className="flex flex-col gap-4 p-4 ">
          <h2 className="custom-h5 text-gray-800 line-clamp-1 hover:line-clamp-none  transition-all duration-300 hover:text-blue-600">{title}</h2>
          <p className=" custom-body-regular line-clamp-2 transition-all duration-300  hover:text-gray-900">{subtitle}</p>
          <a href={Links} target="_blank" className="flex justify-center" rel="noreferrer">
            <button className="rounded-md bg-gray-700 px-5 py-2 text-white shadow-xl transition-all duration-300 hover:bg-blue-700 w-3/4">{ButtonText}</button>
          </a>
        </div>
      </div></a>
    </div>
    </div>
  );
}
const navbarItems = [
  { label: 'Data Lake', href: '#', listName: 'dataLakeList' },
  { label: 'EMR', href: '#', listName: 'emrList' },
  { label: 'GenAI', href: '#', listName: 'GenAI' },
  { label: 'MSK/Kinesis', href: '#', listName: 'mskList' },
  { label: 'OpenSearch', href: '#', listName: 'dataLakeList' },
  { label: 'RDS', href: '#', listName: 'rdsList' },
  { label: 'Redshift', href: '#', listName: 'redshiftList' },
  { label: 'Sagemaker', href: '#', listName: 'sagemakerList' },
  { label: 'TimeStream', href: '#', listName: 'timestreamList' },
  { label: 'AWS Glue', href: '#', listName: 'glueList' },
];

const listNameMap = {};
navbarItems.forEach((item) => {
  listNameMap[item.label] = item.listName;
});

// Accessing the listName using the label

function Main({ selectedFilters, navTitle }) {
  let listName = listNameMap[navTitle];
  // console.log(listName + " is the listname"); // Output: dataLakeList
  const cardData = ListData[listName];


const filteredCardData = (selectedFilters.assetTypes.length === 0 && selectedFilters.industries.length === 0)
  ? cardData
  : cardData.filter(data => {
      const assetTypeIncluded = selectedFilters.assetTypes.includes(data.AssetType);
      const industryIncluded = selectedFilters.industries.includes(data.Industry);

      if (selectedFilters.assetTypes.length > 0 && selectedFilters.industries.length === 0) {
        // Include the data if at least one AssetType filter matches
        return assetTypeIncluded;
      }
      
      if (selectedFilters.industries.length > 0 && selectedFilters.assetTypes.length === 0) {
        // Include the data if at least one Industry filter matches
        return industryIncluded;
      }

      // Include the data only if both AssetType and Industry filters match
      return assetTypeIncluded && industryIncluded;
    });


  //Card Component Rendering
  return (
   <main>
      <div className="bg-white font-poppins">
        <div className="px-4 py-16 sm:px-6 sm:py-24  lg:px-0" style={{ paddingTop: '4px',margin:'auto' }}>
          <div className=" grid grid-cols-1 p-4 gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 3xl:grid-cols-5 3xl:gap-x-10">
            {filteredCardData.length > 0 ? (
              filteredCardData.map((data, index) => (
                <div key={index} className="group relative transition-height duration-300 ease-in-out">
                  <Card
                    title={data.Title}
                    subtitle={data.Description}
                    ButtonText={data.ButtonText}
                    Links={data.Links}
                    assetType={data.AssetType}
                    image={data.ImageURL}
                  /> 
                </div>
              ))
            ) : (
              <p className='lg:w-96'></p>
            )}
          </div>
        </div>
      </div>
    </main>
    
  );
}

export default Main;
