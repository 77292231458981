import React, {useState} from 'react';
import './customstyles.css'
const Header = ({navTitle}) => {
  const [title, setTitle] = useState(navTitle);
  React.useEffect(() => {
    setTitle(navTitle);
  }, [navTitle]);

  return (
    <header className="bg-white mt-3 font-poppins">
      <div className=" min-w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <h3 className=" custom-h4 text-gray-800 ">Mactores AWS Asset - {title}</h3>
        <p className="custom-body-regular dark:text-gray-900">We Encourage you to visit us periodically to check the latest
          updates.</p>
      </div>
      <div className="divider my-0"></div>
    </header>
  );
}

export default Header;
